import { AuthState } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';
import { Action } from 'redux';
import { fetchSearchSources, fetchSources } from './Operations';
import { Action as AnyAction } from '@reduxjs/toolkit';
import { ISourceRequestParams } from '../container/SourcesPage';

export enum SourcesActionTypes {
  FetchSourcesPending = "FETCH_SOURCES_PENDING",
  FetchSourcesSuccess = "FETCH_SOURCES_SUCCESS",
  FetchSourcesRejected = "FETCH_SOURCES_REJECTED"
}

export class FetchSourcesPendingAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesPending;
}

export class FetchSourcesSuccessAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesSuccess;
  constructor(public payload: { sources: Api.ISourcesResponse, operationType: "list" | "search"}) {  }
}

export class FetchSourcesFailedAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesRejected;
  constructor(public payload: { error: Api.ISourcesErrorData }) {  }
}

export type SourcesAction = FetchSourcesPendingAction
  | FetchSourcesSuccessAction
  | FetchSourcesFailedAction;


export const getSourcesThunk: (auth: AuthState, requestParams: ISourceRequestParams) => any = (auth, requestParams) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchSourcesPendingAction());
    fetchSources(auth, requestParams)
      .then((response: Api.ISourcesResponse) => {
        dispatch(new FetchSourcesSuccessAction({ sources: response, operationType: "list"}));
      })
      .catch((errorResponse: Api.ISourcesErrorData) => {
        dispatch(new FetchSourcesFailedAction({ error: errorResponse }));
      });
  }
}

export const getSearchSourcesThunk: (auth: AuthState, searchText:string, requestParams: ISourceRequestParams) => any = (auth, searchText, requestParams) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchSourcesPendingAction());
    fetchSearchSources(auth, searchText, requestParams)
      .then((response: Api.ISourcesResponse) => {
        dispatch(new FetchSourcesSuccessAction({ sources: response, operationType: "search" }));
      })
      .catch((errorResponse: Api.ISourcesErrorData) => {
        dispatch(new FetchSourcesFailedAction({ error: errorResponse }));
      });
  }
}